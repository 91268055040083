import { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { media } from '@dfds-ui/theme'
import {
  Banner,
  BannerHeadline,
  BannerParagraph,
  BannerAction,
} from '@dfds-ui/react-components'
import {
  ShieldSuccess,
  Information,
  StatusAlert,
  StatusSuccess,
} from '@dfds-ui/icons/system'
import { ArrivalsFerry } from '@dfds-ui/icons'

import { useLocaleContext } from '../LocaleContext'

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`

const allIcons = {
  Information,
  ArrivalsFerry,
  StatusAlert,
  StatusSuccess,
}

const PaxCovidBanner = ({
  header,
  content,
  cta1Icon,
  cta1Text,
  cta1Link,
  cta2Icon,
  cta2Text,
  cta2Link,
  cta3Icon,
  cta3Text,
  cta3Link,
}) => {
  const [handleClose, setHandleClose] = useState(
    typeof sessionStorage !== 'undefined' &&
      sessionStorage.covidPaxBannerNotVisible
      ? sessionStorage.covidPaxBannerNotVisible === 'true'
      : false,
  )

  const { localeLower } = useLocaleContext()

  const CTA1Icon = allIcons[cta1Icon],
    CTA2Icon = allIcons[cta2Icon],
    CTA3Icon = allIcons[cta3Icon],
    Actions = []

  if (cta1Text && cta1Link)
    Actions.push(
      <BannerAction
        key={'action-one'}
        variation="primary"
        onClick={() =>
          (window.location.href = `/${localeLower}/${cta1Link.slug}`)
        }
        css={css`
          width: 100%;
          height: 40px;
          margin-top: 0.5rem;

          ${media.greaterThan('l')`
            width: auto;
            margin: 0;
            margin-right: 1rem;
          `}
        `}
      >
        <ActionWrapper>
          <CTA1Icon />
          {cta1Text}
        </ActionWrapper>
      </BannerAction>,
    )
  if (cta2Text && cta2Link)
    Actions.push(
      <BannerAction
        key={'action-second'}
        variation="primary"
        onClick={() =>
          (window.location.href = `/${localeLower}/${cta2Link.slug}`)
        }
        css={css`
          width: 100%;
          height: 40px;
          margin-top: 0.5rem;

          ${media.greaterThan('l')`
            width: auto;
            margin: 0;
            margin-right: 1rem;
          `}
        `}
      >
        <ActionWrapper>
          <CTA2Icon />
          {cta2Text}
        </ActionWrapper>
      </BannerAction>,
    )
  if (cta3Text && cta3Link)
    Actions.push(
      <BannerAction
        key={'action-three'}
        variation="primary"
        onClick={() =>
          (window.location.href = `/${localeLower}/${cta3Link.slug}`)
        }
        css={css`
          width: 100%;
          height: 40px;
          margin-top: 0.5rem;

          ${media.greaterThan('l')`
            width: auto;
            margin: 0;
            margin-right: 1rem;
          `}
        `}
      >
        <ActionWrapper>
          <CTA3Icon />
          {cta3Text}
        </ActionWrapper>
      </BannerAction>,
    )

  useEffect(() => {
    sessionStorage.covidPaxBannerNotVisible = handleClose
  }, [handleClose])

  if (handleClose) return null

  return (
    <Banner
      css={css`
        margin: -40px 0 40px;
        padding: 16px 16px 8px;

        .BannerActions {
          width: 100%;
          justify-content: flex-start;
          margin-left: 48px;
        }
      `}
      icon={ShieldSuccess}
      sticky={false}
      variant={'highEmphasis'}
      intent={'info'}
      onRequestClose={() => setHandleClose(true)}
      actions={Actions}
    >
      <BannerHeadline>{header}</BannerHeadline>
      <BannerParagraph>{content}</BannerParagraph>
    </Banner>
  )
}

export const covidBannerFragment = graphql`
  fragment CovidBanner on contentful_PassengerCovidBanner {
    header
    content
    cta1Icon
    cta1Text
    cta1Link {
      ...UrlSlug
    }
    cta2Icon
    cta2Text
    cta2Link {
      ...UrlSlug
    }
    cta3Icon
    cta3Text
    cta3Link {
      ...UrlSlug
    }
  }
`

export default PaxCovidBanner
